<!--
 * @Author: dongjia
 * @Date: 2021-08-13 11:46:45
 * @LastEditTime: 2021-08-17 18:31:49
 * @LastEditors: aleaner
 * @Description: 小组详情三级tab页面
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\group\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="content">
    <div v-if="ShowRouterTabs">
      <RouterTabs v-model="activeName" :tabArr="tabs" :paramsData="params" />
    </div>
    <router-view />
  </div>
</template>

<script>
import RouterTabs from "@/base/components/Default/RouterTabs.vue";
export default {
  data() {
    return {
      ShowRouterTabs: true, // 控制tab栏显示隐藏
      listArr: [
        "groupDetail",
        "memberAdmin",
        "groupTopicList",
        "groupPostList",
        "groupActivityList",
        "memberActivity",
        "preference",
        "preferenceChart"
      ], // 带tab栏的页面路由name
      params: {}, // 路由传输数据
      activeName: "groupDetail", //当前选中tab
      // tab配置
      tabs: [
        { label: "小组详情", name: "groupDetail", params: true },
        { label: "成员管理", name: "memberAdmin", params: true },
        { label: "话题管理", name: "groupTopicList", params: true },
        { label: "帖子管理", name: "groupPostList", params: true },
        { label: "活动管理", name: "groupActivityList", params: true },
        { label: "成员活跃度", name: "memberActivity", params: true },
        { label: "互动内容类型偏好", name: "preference", params: true },
        { label: "互动内容类型偏好图表分析", name: "preferenceChart", params: true },
      ],
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  created() {
    this.ShowRouterTabs = this.listArr.includes(this.routeName) ? true : false;
    this.activeName = this.$route.name;
  },
  watch: {
    routeName(val) {
      this.ShowRouterTabs = this.listArr.includes(val) ? true : false;
      if (this.listArr.includes(val)) {
        this.params = this.$route.params;
        this.activeName = val;
      }
    },
  },
  components: { RouterTabs },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
}
</style>